@import './tailwind-variables';

* {
  outline-color: theme('colors.primary') !important;
}

html,
body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: theme('colors.gray.900');
  background: theme('colors.gray.100');
  font-size: 16px; // for easy PX to REM conversion. We apply .body-1 as default
}

html,
body,
#root {
  height: 100%;
}

/* CUSTOMIZATION */

.input-range__slider {
  background: theme('colors.primary') !important;
  border-color: theme('colors.primary') !important;
}

.input-range__track--active {
  background: theme('colors.primary') !important;
}

@function hexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}

@each $name, $color in $themes {
  @if $name == 'default' {
    :root {
      --color-primary: #{hexToRGB(map-get($color, 'primary'))};
      --color-secondary: #{hexToRGB(map-get($color, 'secondary'))};
    }
  }

  .theme-#{$name} {
    --color-primary: #{hexToRGB(map-get($color, 'primary'))};
    --color-secondary: #{hexToRGB(map-get($color, 'secondary'))};
  }
}

// React Input Range (slider)
// $input-range-primary-color: theme('colors.default.primary');
$input-range-neutral-color: theme('colors.gray.700');
$input-range-disabled-color: theme('colors.gray.400');
$input-range-neutral-light-color: theme('colors.gray.500');
// $input-range-slider-background: theme('colors.primary');
$input-range-slider-width: 1.6rem;
$input-range-slider-height: 1.6rem;
$input-range-label-position-bottom: -1.6rem;
$input-range-label-value-position-top: -2.3rem;
$input-range-track-height: 0.5rem;
$transition-time: 150ms;
$input-range-slider-container-transition: left $transition-time ease-out !default;
$input-range-track-transition: left $transition-time ease-out,
  width $transition-time ease-out !default;

@import 'react-input-range/src/scss/index.scss';
